import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { logo } from '../utils/data';

interface SocialLink {
  name: string;
  icon: string;
  url: string;
}

const socialLinks: SocialLink[] = [
  {
    name: 'Email',
    icon: 'https://cdn-icons-png.flaticon.com/512/732/732200.png',
    url: 'mailto:wecare@treaz.in'
  },
  {
    name: 'WhatsApp',
    icon: 'https://cdn-icons-png.flaticon.com/512/733/733585.png',
    url: 'https://wa.me/+919148453611'
  },
  {
    name: 'Instagram',
    icon: 'https://cdn-icons-png.flaticon.com/512/733/733614.png',
    url: 'https://instagram.com/treaz.india'
  },
  {
    name: 'Facebook',
    icon: 'https://cdn-icons-png.flaticon.com/512/733/733547.png',
    url: 'https://www.facebook.com/share/6byfXk9tjZ4aPqnx/?mibextid=qi2Omg'
  },
  {
    name: 'X',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/X_logo_2023.svg/70px-X_logo_2023.svg.png',
    url: 'https://twitter.com/treazindia'
  },
];

const SocialLink: React.FC<SocialLink> = ({ name, icon, url }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="relative group"
    >
      <div className="flex items-center justify-center w-10 h-10 md:w-12 md:h-12 rounded-full bg-white shadow-md hover:shadow-lg">
        <img src={icon} alt={name} className="w-6 h-6 md:w-8 md:h-8" />
      </div>
    </a>
  );
};

const Footer: React.FC = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSubscribe = async () => {
    if (!email.trim()) {
      setMessage("Please enter a valid email.");
      return;
    }

    setLoading(true);
    setMessage(null);

    try {
      const response = await fetch("https://api.treaz.in/subscribe", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      if (response.ok) {
        setMessage("✅ Subscription successful!");
        setEmail("");
      } else {
        setMessage(`❌ Error: ${data.message || "Subscription failed"}`);
      }
    } catch (error) {
      setMessage("❌ Network error. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <footer className="bg-gray-800 text-white z-40 -mb-10" itemScope itemType="http://schema.org/WPFooter">
      <div className="container px-4 py-8">
        {/* Logo Section */}
        <div className="logo">
          <Link to="/" className="flex items-center group">
            <img src={logo} alt="Logo" className="h-8 -mx-1 w-auto" />
          </Link>
        </div>

        {/* Grid for About, Quick Links, Connect, and Subscribe */}
        <div className="grid md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-xl font-bold mb-4">About Us</h3>
            <p className="text-gray-400">
              Treaz offers customized drinking water bottles for brand advertising, turning an everyday essential into a powerful marketing tool.
            </p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-2">📍 Address</h3>
            <p className="text-gray-400">Nehru Extension, Kushalnagar, Kodagu, Karnataka - 571234</p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-2">🕒 Business Hours</h3>
            <p className="text-gray-400">Mon-Sat 8:00 AM - 10:00 PM</p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><a href="/privacy" className="hover:text-blue-400 transition-colors">Privacy Policy</a></li>
              <li><a href="/terms" className="hover:text-blue-400 transition-colors">Terms of Service</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4">Connect</h3>
            <div className="flex gap-4">
              {socialLinks.map((link) => (
                <SocialLink key={link.name} {...link} />
              ))}
            </div>
          </div>
          {/* Newsletter Subscription */}
          <div>
            <h3 className="text-xl font-bold mb-4">📩 Subscribe to Newsletter</h3>
            <div className="flex flex-col sm:flex-row gap-2">
              <input
                type="email"
                placeholder="Enter your email"
                className="p-2 text-black rounded-md border border-gray-300 focus:ring focus:ring-blue-400 w-full sm:w-auto"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                onClick={handleSubscribe}
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md disabled:bg-gray-500"
                disabled={loading}
              >
                {loading ? "Subscribing..." : "Subscribe"}
              </button>
            </div>
            {message && <p className="text-sm mt-2 text-gray-300">{message}</p>}
          </div>
        </div>

        {/* Copyright Section */}
        <div className="border-t border-gray-700 mt-8 pt-4 text-center text-gray-400">
          <p>&copy; 2024-25 Treaz . All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
